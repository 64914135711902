import { defineStore } from 'pinia'

export interface User {
  id: number
  name: string
  email: string
  oib: string
  username: string
  active: boolean
  password?: string
  companyId: number
}

export const useUserStore = defineStore('user', {
  state: (): User => {
    return {
      id: 0,
      name: '',
      email: '',
      oib: '',
      username: '',
      active: true,
      password: '',
      companyId: 0,
    }
  },

  actions: {
    setUser(user: User) {
      Object.assign(this, user)
    },
    changeUserName(name: string) {
      this.name = name
    },
  },
})
