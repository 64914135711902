import axios from 'axios'
import { useAuthStore } from '../stores'

const API_URL = import.meta.env.VITE_API_URL

const createApi = () => {
  const api = axios.create({ baseURL: API_URL })

  api.interceptors.request.use(async (config) => {
    const { user } = useAuthStore()
    config.headers.Authorization = `Bearer ${user?.token}`

    return config
  })

  return api
}

export const api = createApi()
