import { defineStore } from 'pinia'
import { api } from '../services/axios'
import router from '../router'
import { ref, watch } from 'vue'
import { useUserStore } from './user-store'

interface User {
  id: number
  username: string
  token: string
}

function useLocalStorage<T>(key: string, defaultValue?: T) {
  const val = ref(defaultValue)

  const storageVal = localStorage.getItem(key)
  if (storageVal) {
    val.value = JSON.parse(storageVal)
  }
  watch(val, (newVal) => localStorage.setItem(key, JSON.stringify(newVal))), { deep: true }
  return val.value
}

export const useAuthStore = defineStore('auth', {
  state: (): {
    user: User | undefined
    returnUrl: string | null
    isUserAuthenticated: boolean
    token: string | undefined
  } => ({
    user: useLocalStorage<User>('auth.user', undefined),
    returnUrl: null,
    isUserAuthenticated: false,
    token: useLocalStorage<string>('auth.token', undefined),
  }),
  actions: {
    initializeUser(): void {
      if (this.user && this.token) {
        this.isUserAuthenticated = true
      }
    },
    async login(email: string, password: string): Promise<void> {
      try {
        const response = await api.post(`auth/login`, { email, password })
        console.log(response.data)
        this.user = response.data
        this.isUserAuthenticated = true

        localStorage.setItem('auth.user', JSON.stringify(response.data))
        localStorage.setItem('auth.token', JSON.stringify(response.data.token))

        console.log(this.user)
        console.log(this.isUserAuthenticated)

        const userStore = useUserStore()
        userStore.setUser(response.data.user)
      } catch (error) {
        // to do
        console.log(error)
        // this.isAuthenticated = false
        // const alertStore = useAlertStore();
        // alertStore.error(error);
      }
    },
    logout(): void {
      this.user = undefined
      this.isUserAuthenticated = false
      localStorage.removeItem('auth.user')
      localStorage.removeItem('auth.token')
      router.push('/account/login')
    },
  },
  getters: {
    isAuthenticated(): boolean {
      return this.isUserAuthenticated
    },
  },
})
